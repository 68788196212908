import axios from "axios";
import { handleError } from "./Errors";

export default class KnoxService{
    static async resetApp(uid:string):Promise<any>{
        try {
            const response = await axios.post(
                `/api/knox/resetApp?tabletId=${uid}`
            );
            } catch (err) {
            return handleError(err, false);
        }
    }

    static async createGroup(accessGroups:number[],name:string):Promise<any>{
        try {
            if (accessGroups.length>0){
            const response = await axios.post(
                `/api/knox/createGroup?accessgroups=`+accessGroups+'&groupname='+name
            );
            return response}
            } catch (err) {
            return handleError(err, false);
        }
    }

    static async sendNotification(id:number,title:string,message:string):Promise<any>{
        try {
            const response = await axios.post(
                `/api/knox/sendNotification?robotId=${id}`,{title,message}
            );
            return response
            } catch (err) {
            return handleError(err, false);
        }
    }

    static async lockDevice(id:number,lock:boolean,message:string):Promise<any>{
        try {
            const response = await axios.post(
                `/api/knox/lockDevice?robotId=${id}&lock=${lock}`,{message}
            );
            return response
            } catch (err) {
            return handleError(err, false);
        }
    }
}