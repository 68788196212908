import React, { useCallback, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";

import { useSelector } from "react-redux";

import BaseView from "src/views/BaseView";
import Section from "./Section";
import DeviceDataService, { DeviceData, DeviceDetails } from "src/services/DeviceDataService";
import { useAsync, useDidMountCheck, useEvents } from "src/hooks";
import { customersSelector } from "src/redux/app/selectors";
import DashboardProvider, { update, useDashboard } from "./DashboardProvider";
import { DeviceType } from "src/utils/constants";
import CommandService from "src/services/CommandService";
import ConfigurationsService from "src/services/ConfigurationsService";
import { DataPayload } from "src/events";

export interface DashboardProps {
    deviceTypeId: number;
    deviceId: number;
}

function Updater() {
    const { dispatch } = useDashboard();
    const updater = useCallback(
        (events: any) => {
            dispatch(update(events));
        },
        [dispatch]
    );
    useEvents("data", updater);
    return <></>;
}

interface RerenderProps {
    newEvents: any[];
    setNewevents: (events: any[]) => void;
}

const Rerender: React.FC<RerenderProps> = ({ newEvents,setNewevents }) => {
    const { dispatch } = useDashboard();
    useEffect(()=>{
        if (newEvents.length > 0){
            dispatch(update({ events: newEvents , type: "UPDATE" }))
            setNewevents([])
        }
        }
    ,[newEvents,setNewevents,dispatch]);

    return <></>;
};



export default React.memo(function Dashboard(props: DashboardProps) {
    const { deviceTypeId, deviceId } = props;

    const [latestdata,setLatestData]=useState(undefined as never as DeviceDetails);
    const [newEvents,setNewevents]=useState([] as any[]);
    const getDetailsCallback = useCallback(() => {
        return DeviceDataService.getDetailedDevice(deviceTypeId, deviceId).then(async r =>
            {
                if(deviceTypeId === 1)
                {
                    const index = r.primary.groups?.findIndex(x => x.name === "Miscellaneous");
                    if(r.primary.groups && index !== undefined)
                        {
                            r.primary.groups[index].keys.push("Latest Version");
                            const latestVersionAsDeviceData = {
                                deviceId: deviceId,
                                deviceTypeId: 1,
                                key: "Latest Version",
                                value: "Loading...",
                                created: 0,
                                inserted: 0
                            } as DeviceData
    
                            r.primary.data["Latest Version"] = latestVersionAsDeviceData;
                        }  
                        
                        if(r.secondary?.groups && r.secondary?.deviceTypeId === 2)
                            {
                                const stateIndex = r.secondary.groups?.findIndex(x => x.name === "State");
                                r.secondary.groups[stateIndex].keys.push("Latest App Version");
                                for(const secondaryDeviceId of r.secondary.deviceIds)
                                {
                                    const latestAppVersionAsDeviceData = {
                                        deviceId: secondaryDeviceId,
                                        deviceTypeId: 2,
                                        key: "Latest App Version",
                                        value: "Loading...",
                                        created: 0,
                                        inserted: 0
                                    } as DeviceData;
    
                                    if(r.secondary)
                                    {
                                        r.secondary.devices[secondaryDeviceId].keys.push("Latest App Version");
                                        r.secondary.devices[secondaryDeviceId].data["Latest App Version"] = latestAppVersionAsDeviceData;
                                    } 
                                }
                            }    
                }
                setLatestData(r);
                if(deviceTypeId === 1)
                {
                    const version = await CommandService.getRobotLatestVersion(deviceId);
                    const index = r.primary.groups?.findIndex(x => x.name === "Miscellaneous");

                    //append latest available version for robot and tablets as device data
                    if(r.primary.groups && index !== undefined)
                    {
                        setNewevents([...newEvents,{id:r.primary.deviceId+'',type:r.primary.deviceTypeId+'',payload:{id:r.primary.deviceId,type:r.primary.deviceTypeId,entries:[["Latest Version",version,0,0]]}}])
                        if(r.secondary?.groups && r.secondary?.deviceTypeId === 2)
                        {
                            for(const secondaryDeviceId of r.secondary.deviceIds)
                            {
                                const appVersion = await ConfigurationsService.getTabletAppLatestVersion(secondaryDeviceId);
                                if(r.secondary)
                                {
                                    setNewevents([...newEvents,{id:secondaryDeviceId+'',type:r.secondary.deviceTypeId+'',payload:{id:secondaryDeviceId,type:r.secondary.deviceTypeId,entries:[["Latest App Version",appVersion,0,0]]}}])
                                } 
                            }
                        }
                    }
                }
                 
                 return r;
            });
    }, [deviceTypeId, deviceId]); // eslint-disable-line react-hooks/exhaustive-deps

    const { dto: customers, loaded: customersLoaded } = useSelector(
        customersSelector
    );
    const { value: data, pending, error, exec: getDetails } = useAsync(
        getDetailsCallback,
        {
            defaultPending: true,
        }
    );

    const loading =
        (latestdata === undefined && error === undefined) 
        ||
        !customersLoaded;
    useDidMountCheck("Dashboard");
    return (<BaseView loading={loading} error={error} retry={getDetails}>
            <DashboardProvider details={(latestdata)}>
                <Container component="main" maxWidth="xl">
                    <Updater />
                    <Rerender newEvents={newEvents} setNewevents={setNewevents}/>
                    <Section
                        primaryDeviceTypeId={deviceTypeId}
                        customers={customers}
                        primary={deviceTypeId !== DeviceType.Tablet}
                    />
                    
                    
                    <Section
                        primaryDeviceTypeId={deviceTypeId}
                        customers={customers}
                        primary={deviceTypeId === DeviceType.Tablet}
                        inaccessibleDevices={latestdata?.omittedInaccessibleDevices}
                    />
                    
                </Container>
            </DashboardProvider>
         </BaseView>
    );
});
