import { Moment } from "moment";
import { PDFArray, PDFDict, PDFDocument, PDFName, PDFObject, PDFString, StandardFonts, rgb } from "pdf-lib";
import { Contract, Plan, Quote } from "src/services/QuoteService";
import { User } from "src/services/UserService";
import { formatAsMoney } from "src/utils/common";

const PAGE_WIDTH = 600;
const TABLE_WIDTH = 550;
const HEADER_HEIGHT = 50;
const LINE_HEIGHT = 25;
const BORDER = 1;
// const COLUMN_1 = 215;
// const COLUMN_2 = 95;
// const COLUMN_3 = 130;
// const COLUMN_4 = 110;
const PAGE_START = 760;
const HEADER_PADDING = 10;
const CELL_PADDING = 3;
const PAGE_HEIGHT = 660;
const TABLE_PADDING = 20;

export interface PdfState {
    user: User;
    quotes: Quote;
    quotationNumber: number;
    date: Moment;
    timezone: number;
    pdfs: { [name: string]: Blob };
    contract: Contract;
    validDays: number;
    comments: string;
    companyName: string;
    companyPhone: string;
    companyCountry: string;
    companyCity: string;
    companyAddress: string;
    companyZip: string;
    companyState: string;
    contactFirstname: string;
    contactLastname: string;
    contactEmail: string;
    contactPhone: string;
    robotCount: { [name: string]: number };
    plans: { [name: string]: Array<Plan> };
    templates: { [name: string]: Array<Array<number>> };
    extras: { [name: string]: Array<{ [name: number]: number }> };
    splitExtras: boolean;
    language:string;
    quoteTranslator: Array<{lang:string,key:string,value:string}>,
    itemNumbers: Array<{lang:string,key:string,value:string}>,
    quoteType: string
}

function getQuotationString(user: User, quotationNumber: number) {
    const name = (user.firstname&&user.lastname&&(user.firstname+" "+user.lastname))||user.username;
    let num = quotationNumber.toString();
    while (num.length < 3)
        num = "0"+num;
    let rnd = (Math.floor(Math.random() * 9) + 1).toString();
    while (rnd.length < 1)
        rnd = "0"+rnd;
    return name.replace(/\s+/g,"")+"-"+num+""+rnd;
}

const getColumnWidth=(column:number,language:string)=>{
    let columns=[80,210,65,105,90]
    if (language==='de')
        columns=[90,225,45,105,85]
    if (language==='nl')
        columns=[90,190,85,95,90]    
    if (language==='fr')
        columns=[80,200,60,115,95]
    return columns[column-1]
}

export async function createPdf(q: PdfState) {
    const lineItems = new Array<{name:string,qty:number,price:number,itemNumber?:string}>(0);
    const quotationString = getQuotationString(q.user, q.quotationNumber);
    const pdfCover = await PDFDocument.load(await q.pdfs[q.contract.pdfCover].arrayBuffer());
     
    const translate=(key:string)=>((q.quoteTranslator.find((tr)=>tr.key===key && tr.lang===q.language)?.value??q.quoteTranslator.find((tr)=>tr.key===key && tr.lang==="eng_us")?.value)??key)
    const getNumber=(key:string)=>q.itemNumbers.find((n)=>n.key===key && n.lang===q.language)?.value??q.itemNumbers.find((n)=>n.key===key && n.lang==='eu')?.value??''
    
    const COLUMN_1=getColumnWidth(1,q.language)
    const COLUMN_2=getColumnWidth(2,q.language)
    const COLUMN_3=getColumnWidth(3,q.language)
    const COLUMN_4=getColumnWidth(4,q.language)
    const COLUMN_5=getColumnWidth(5,q.language)

    
    const isUSA = (q.contract?.countryDropdown??[]).includes("United States");
    const isVAT= !isUSA//(q.contract?.countryDropdown??[]).includes("United Kingdom");
    const isDot= (q.contract?.countryDropdown??[]).includes("United Kingdom") || (q.contract?.countryDropdown??[]).includes("Australia") || isUSA 
    const stateZip = q.companyState+" "+q.companyZip;
    pdfCover.getPage(0).drawText(
        q.companyName+"\n"+
        q.companyAddress+"\n"+
        q.companyCity+(stateZip.trim()&&`, ${stateZip}`)+"\n"+
        q.companyCountry+"\n"+
        q.companyPhone+"\n"+
        q.contactFirstname+" "+q.contactLastname+"\n"+
        q.contactEmail+"\n"+
        q.contactPhone
    , { x: 271, y: 637, size: 14, maxWidth: 300 });
    pdfCover.getPage(0).drawText(q.date.utcOffset(q.timezone).format(isUSA?"MM/DD/YY":"DD/MM/YY"), { x: 45, y: 515, size: 14 });
    pdfCover.getPage(0).drawText(quotationString, { x: 45, y: 453, size: 14 });
    pdfCover.getPage(0).drawText(q.date.clone().add(q.validDays,"days").utcOffset(q.timezone).format(isUSA?"MM/DD/YY":"DD/MM/YY"), { x: 45, y: 391, size: 14 });
    pdfCover.getPage(0).drawText(q.user.firstname+" "+q.user.lastname+"\n"+q.user.email, { x: 45, y: 330, size: 12, maxWidth: 190, wordBreaks: [""] });
    pdfCover.getPage(0).drawText(q.comments, { x: 271, y: 404, size: 14, maxWidth: 300 });
    const pdfPayments = new Array<PDFDocument>(0);
    const tables = new Array<{title: string, lines: Array<Array<string | number>>, height: number}>(0);
    const paymentPlanLines = Array<number>(0);
    let planCurrency = "";
    let totalToSplit = 0;
    let planSum = 0;
    let isMonthly = false;
    for (const prod of Object.keys(q.robotCount))
        for (let i = 0; i < q.robotCount[prod]; i++) {
            if (!q.plans[prod] || !q.plans[prod][i])
                continue;
            const lines = new Array<Array<string | number>>(0);
            lines.push([translate("Item number"),translate("Description"), translate("Quantity"), translate("Unit Price")+" ("+q.plans[prod][i].currency+")", translate("Amount")+" ("+q.plans[prod][i].currency+")"]);
            planCurrency = q.plans[prod][i].currency;
            let sum = 0;
            for (const [index,item] of q.plans[prod][i].items.entries()) {
                const numb=getNumber(index===0?q.plans[prod][i].product:(item.desc==='Annual Fee'?item.desc+item.qty:item.desc))
                lines.push([numb,translate(item.desc), item.qty, item.price, (item.qty*item.price)]);
                lineItems.push({itemNumber:numb,name:translate(item.desc),qty:item.qty,price:item.price});
                sum += item.qty*item.price;
            }
            for (const id of Object.keys(q.extras[prod][i]))
                if (q.extras[prod][i][Number(id)] > 0) {
                    const extra = q.plans[prod][i].extras.find(e => e.id === Number(id))!;
                    const numb=getNumber((extra.name==='1 Year of Extra Warranty' || extra.name==="1 Year Subscription")?(extra.name+q.extras[prod][i][Number(id)]):extra.name)
                    lines.push([numb,translate(extra.name), q.extras[prod][i][Number(id)], extra.price, (q.extras[prod][i][Number(id)]*extra.price)]);
                    lineItems.push({itemNumber:numb,name:translate(extra.name),qty:q.extras[prod][i][Number(id)],price:extra.price});
                    if (extra.name==="Battery-36V-17400-3pol-w/Handle"){
                        let number="220-1027-11"
                        let name=translate('EU Battery Charger-incl. Power Cord (C7)')
                        if (q.contract.countryDropdown.includes('United States')){
                            name=translate("CA/JP/US/CN Battery Charger-incl. Power Cord (C7)")
                            number="220-1027-21"
                        }
                        if (q.contract.countryDropdown.includes('Australia')){
                            name=translate("AU/NZ Battery Charger-incl. Power Cord (C7)")
                            number="220-1027-31"
                        }
                        lines.push([number,translate("Battery charger"), q.extras[prod][i][Number(id)], 0, 0]);
                        lineItems.push({itemNumber:number,name:translate("Battery charger"),qty:q.extras[prod][i][Number(id)],price:0});
                    }
                    sum += (q.extras[prod][i][Number(id)]*extra.price);
                    totalToSplit += q.extras[prod][i][Number(id)]*extra.price;
                }
            if (i === 0)
                for (const t of q.plans[prod][i].templates.filter(te => prod.toLowerCase().includes("sport") || te.name.toLowerCase().includes("custom"))) {
                    const isCustom = t.name.toLowerCase().includes("custom");
                    const free = (q.quotes?.freeTemplates ?? []).includes(t.id) || t.price === 0;
                    const freeTemplatesQuantity = (q.quotes?.freeTemplatesQty ?? 0)*q.robotCount[prod];
                    const includedForFree = (!q.templates[prod][i].includes(t.id) && q.templates[prod][i].length >= ((q.quotes?.freeTemplates ?? []).length + freeTemplatesQuantity + (q.quotes?.allFreeAfterQty ?? 0)));
                    const cheapest = q.plans[prod][i].templates.filter(te => q.templates[prod][i].includes(te.id) && te.price > 0 && !(q.quotes?.freeTemplates ?? []).includes(te.id)).sort((a, b) => b.price-a.price);
                    const selectedFree = cheapest.slice(0, cheapest.length<freeTemplatesQuantity?cheapest.length:freeTemplatesQuantity).map(te => te.id).includes(t.id);
                    const overAllFree = !isCustom && (free || includedForFree || selectedFree);
                    if (q.templates[prod][i].includes(t.id) || (!isCustom && q.templates[prod][i].length >= ((q.quotes?.freeTemplates ?? []).length + freeTemplatesQuantity + (q.quotes?.allFreeAfterQty ?? 0)))) {
                        lines.push([getNumber(t.name),translate(t.name), 1, t.price, overAllFree?0:t.price]);
                        lineItems.push({itemNumber:getNumber(t.name),name:translate(t.name),qty:1,price:overAllFree?0:t.price});
                        sum += overAllFree?0:t.price;
                        totalToSplit += overAllFree?0:t.price;
                    }
                }
            if (q.language==='de'){
                lines.push(["","Gesamtsumme (zzgl. MwSt.)", "", "", sum]);
                lines.push(["","Mehrwertsteuer   (19%)", "", "", sum*0.19]);
                lines.push(["","Gesamtsumme (inkl. MwSt.)", "", "", sum*1.19]);    
            }
            else
            lines.push(["",translate("Total")+(isVAT?" ("+translate('subject to VAT')+")":''), "", "", sum]);

            planSum += sum;
            tables.push({title: q.plans[prod][i].product+" "+translate(q.plans[prod][i].title), lines, height: BORDER+HEADER_HEIGHT+(LINE_HEIGHT+BORDER)*lines.length});
            // PRICING PLAN TABLE
            if (q.plans[prod][i].isMonthly)
                isMonthly = true;
            for (let j = 0; j < q.plans[prod][i].plan.length; j++) {
                if (paymentPlanLines.length <= j)
                    paymentPlanLines.push(q.plans[prod][i].plan[j]);
                else
                    paymentPlanLines[j] += q.plans[prod][i].plan[j];
            }
        }
    let spaceLeft = PAGE_HEIGHT;
    let pdfPayment;
    for (const table of tables) {
        const lines = table.lines;
        if ((spaceLeft < table.height && spaceLeft !== PAGE_HEIGHT) || pdfPayment === undefined) {
            pdfPayment = await PDFDocument.load(await q.pdfs[q.contract.pdfPayment].arrayBuffer());
            pdfPayments.push(pdfPayment);
            spaceLeft = PAGE_HEIGHT;
        }
        const font = await pdfPayment.embedFont(StandardFonts.Helvetica);
        const offset = PAGE_HEIGHT - spaceLeft;
        spaceLeft -= table.height + TABLE_PADDING;
        pdfPayment.getPage(0).drawRectangle({x: (PAGE_WIDTH-2*BORDER-TABLE_WIDTH)/2, y: PAGE_START-(lines.length)*(LINE_HEIGHT+BORDER)-HEADER_HEIGHT-3*BORDER-offset, width: 2*BORDER+TABLE_WIDTH, height: (lines.length)*(LINE_HEIGHT+BORDER)+HEADER_HEIGHT+2*BORDER, color: rgb(0,0,0)});
        pdfPayment.getPage(0).drawRectangle({x: (PAGE_WIDTH-TABLE_WIDTH)/2, y: PAGE_START-HEADER_HEIGHT-2*BORDER-offset, width: TABLE_WIDTH, height: HEADER_HEIGHT, color: rgb(3/255,100/255,139/255)});
        pdfPayment.getPage(0).drawText(table.title, {x: (PAGE_WIDTH-TABLE_WIDTH)/2+HEADER_PADDING, y: PAGE_START-HEADER_HEIGHT/3*2-offset, size: 16, color: rgb(1,1,1)});
        for(let j = 0; j < lines.length; j++) {
            //
            pdfPayment.getPage(0).drawRectangle({x: (PAGE_WIDTH-TABLE_WIDTH)/2, y: PAGE_START-HEADER_HEIGHT-2*BORDER-(j+1)*(LINE_HEIGHT+BORDER)-offset, width: COLUMN_1, height: LINE_HEIGHT, color: rgb(230/255,230/255,230/255)});
            pdfPayment.getPage(0).drawText(lines[j][0].toString().split(" (")[0], {x: (PAGE_WIDTH-TABLE_WIDTH)/2+CELL_PADDING, y: PAGE_START-HEADER_HEIGHT-2*BORDER-(j+0.66666)*(LINE_HEIGHT+BORDER)-offset, size: 13, color: rgb(0,0,0)});
            //
            pdfPayment.getPage(0).drawRectangle({x: (PAGE_WIDTH-TABLE_WIDTH)/2+COLUMN_1+BORDER, y: PAGE_START-HEADER_HEIGHT-2*BORDER-(j+1)*(LINE_HEIGHT+BORDER)-offset, width: COLUMN_2-BORDER, height: LINE_HEIGHT, color: j===0?rgb(230/255,230/255,230/255):rgb(1,1,1)});
            pdfPayment.getPage(0).drawText(lines[j][1].toString(), {x: (PAGE_WIDTH-TABLE_WIDTH)/2+COLUMN_1+BORDER+CELL_PADDING, y: PAGE_START-HEADER_HEIGHT-2*BORDER-(j+0.66666)*(LINE_HEIGHT+BORDER)-offset, size: 13, color: rgb(0,0,0)});
            //
            pdfPayment.getPage(0).drawRectangle({x: (PAGE_WIDTH-TABLE_WIDTH)/2+COLUMN_1+COLUMN_2+BORDER, y: PAGE_START-HEADER_HEIGHT-2*BORDER-(j+1)*(LINE_HEIGHT+BORDER)-offset, width: COLUMN_3-BORDER, height: LINE_HEIGHT, color: j===0?rgb(230/255,230/255,230/255):rgb(1,1,1)});
            pdfPayment.getPage(0).drawText(lines[j][2].toString(), {x: (PAGE_WIDTH-TABLE_WIDTH)/2+COLUMN_1+COLUMN_2+BORDER+CELL_PADDING, y: PAGE_START-HEADER_HEIGHT-2*BORDER-(j+0.66666)*(LINE_HEIGHT+BORDER)-offset, size: 13, color: rgb(0,0,0), font});
            //
            pdfPayment.getPage(0).drawRectangle({x: (PAGE_WIDTH-TABLE_WIDTH)/2+COLUMN_1+COLUMN_2+COLUMN_3+BORDER, y: PAGE_START-HEADER_HEIGHT-2*BORDER-(j+1)*(LINE_HEIGHT+BORDER)-offset, width: COLUMN_4-BORDER, height: LINE_HEIGHT, color: j===0?rgb(230/255,230/255,230/255):rgb(1,1,1)});
            let txt, txtWidth, hPos;
            txt = (typeof lines[j][3] === "string")?(lines[j][3] as string):formatAsMoney(lines[j][3] as number,isDot);
            txtWidth = font.widthOfTextAtSize(txt, 13);
            if (j > 0)
                hPos = (PAGE_WIDTH-TABLE_WIDTH)/2+COLUMN_1+COLUMN_2+COLUMN_3+COLUMN_4-txtWidth+BORDER-CELL_PADDING;
            else
                hPos = (PAGE_WIDTH-TABLE_WIDTH)/2+COLUMN_1+COLUMN_2+COLUMN_3+BORDER+CELL_PADDING;

            pdfPayment.getPage(0).drawText(txt, {x: hPos, y: PAGE_START-HEADER_HEIGHT-2*BORDER-(j+0.66666)*(LINE_HEIGHT+BORDER)-offset, size: 13, color: rgb(0,0,0), font});
            //
            pdfPayment.getPage(0).drawRectangle({x: (PAGE_WIDTH-TABLE_WIDTH)/2+COLUMN_1+COLUMN_2+COLUMN_3+COLUMN_4+BORDER, y: PAGE_START-HEADER_HEIGHT-2*BORDER-(j+1)*(LINE_HEIGHT+BORDER)-offset, width: COLUMN_5-BORDER, height: LINE_HEIGHT, color: j===0?rgb(230/255,230/255,230/255):rgb(1,1,1)});
            if (lines[j][4] === 0)
                txt = translate("FREE");
            else if (typeof lines[j][4] === "string")
                txt = lines[j][4] as string;
            else
                txt = formatAsMoney(lines[j][4] as number,isDot);

            txtWidth = font.widthOfTextAtSize(txt, 13);
            if (j > 0)
                hPos = (PAGE_WIDTH-TABLE_WIDTH)/2+COLUMN_1+COLUMN_2+COLUMN_3+COLUMN_4+COLUMN_5-txtWidth+BORDER-CELL_PADDING;
            else
                hPos = (PAGE_WIDTH-TABLE_WIDTH)/2+COLUMN_1+COLUMN_2+COLUMN_3+COLUMN_4+BORDER+CELL_PADDING;
            pdfPayment.getPage(0).drawText(txt, {x: hPos, y: PAGE_START-HEADER_HEIGHT-2*BORDER-(j+0.66666)*(LINE_HEIGHT+BORDER)-offset, size: 13, color: rgb(0,0,0), font});
        }
    }
    if ((Object.values(q?.plans).flat(1).length!==1 || Object.values(q?.plans).flat(1)?.filter((p:any)=>p.title==='Standard Payment' || p.title==='All Inclusive').length!==1) && !isUSA){

    if (paymentPlanLines.length > 0) {
        if ((spaceLeft < BORDER+HEADER_HEIGHT+(LINE_HEIGHT+BORDER)*(paymentPlanLines.length+2) && spaceLeft !== PAGE_HEIGHT) || pdfPayment === undefined) {
            pdfPayment = await PDFDocument.load(await q.pdfs[q.contract.pdfPayment].arrayBuffer());
            pdfPayments.push(pdfPayment);
            spaceLeft = PAGE_HEIGHT;
        }
        const offset = PAGE_HEIGHT - spaceLeft;
        const lines = new Array<Array<string | number>>(0);
        lines.push([translate("Year"), translate("Amount")+" ("+planCurrency+")"]);
        if (isMonthly) {
            lines[0][0] = "";
            let monthly = 0;
            paymentPlanLines.forEach((a, idx) => {
                if (idx === 0) {
                    if (q.splitExtras)
                        lines.push([translate("Year")+" 1", formatAsMoney(a+Math.floor(totalToSplit/paymentPlanLines.length),isDot)+(isVAT?translate("+VAT"):"")]);
                    else
                    lines.push([translate("Year")+" 1", formatAsMoney(a+totalToSplit,isDot)+(isVAT?translate("+VAT"):"")]);
                } else {
                    if (q.splitExtras)
                        monthly += a+totalToSplit/paymentPlanLines.length;
                    else
                        monthly += a;
                }
            });
            lines.push([`${translate('Monthly')} (${translate('Year')} 2-${paymentPlanLines.length})`, (12*(paymentPlanLines.length-1))+" x "+formatAsMoney((monthly/(paymentPlanLines.length-1)/12.0),isDot) +(isVAT?translate("+VAT"):"") ]);        }
             else {
            paymentPlanLines.forEach((a, idx) => {
                if (q.splitExtras)
                    lines.push([idx+1, formatAsMoney(a+Math.floor(totalToSplit/paymentPlanLines.length),isDot)+(isVAT?translate("+VAT"):"")]);
                else if (idx === 0)
                    lines.push([idx+1, formatAsMoney(a+totalToSplit,isDot)+(isVAT?translate("+VAT"):"")]);
                else
                    lines.push([idx+1, formatAsMoney(a,isDot)+(isVAT?translate("+VAT"):"")]);
            });
        }
        
        lines.push([translate("Total"), formatAsMoney(paymentPlanLines.reduce((partialSum:number, a) => partialSum + a, 0)+totalToSplit,isDot)+(isVAT?translate("+VAT"):"")]);
        pdfPayment.getPage(0).drawRectangle({x: (PAGE_WIDTH-2*BORDER-TABLE_WIDTH)/2, y: PAGE_START-(lines.length)*(LINE_HEIGHT+BORDER)-HEADER_HEIGHT-3*BORDER-offset, width: 2*BORDER+TABLE_WIDTH, height: (lines.length)*(LINE_HEIGHT+BORDER)+HEADER_HEIGHT+2*BORDER, color: rgb(0,0,0)});
        pdfPayment.getPage(0).drawRectangle({x: (PAGE_WIDTH-TABLE_WIDTH)/2, y: PAGE_START-HEADER_HEIGHT-2*BORDER-offset, width: TABLE_WIDTH, height: HEADER_HEIGHT, color: rgb(3/255,100/255,139/255)});
        pdfPayment.getPage(0).drawText(translate("Payment Plan"), {x: (PAGE_WIDTH-TABLE_WIDTH)/2+HEADER_PADDING, y: PAGE_START-HEADER_HEIGHT/3*2-offset, size: 16, color: rgb(1,1,1)});
        for(let j = 0; j < lines.length; j++) {
            pdfPayment.getPage(0).drawRectangle({x: (PAGE_WIDTH-TABLE_WIDTH)/2, y: PAGE_START-HEADER_HEIGHT-2*BORDER-(j+1)*(LINE_HEIGHT+BORDER)-offset, width: COLUMN_1+COLUMN_2, height: LINE_HEIGHT, color: rgb(230/255,230/255,230/255)});
            pdfPayment.getPage(0).drawText(lines[j][0].toString(), {x: (PAGE_WIDTH-TABLE_WIDTH)/2+CELL_PADDING, y: PAGE_START-HEADER_HEIGHT-2*BORDER-(j+0.66666)*(LINE_HEIGHT+BORDER)-offset, size: 13, color: rgb(0,0,0)});
            pdfPayment.getPage(0).drawRectangle({x: (PAGE_WIDTH-TABLE_WIDTH)/2+COLUMN_1+COLUMN_2+BORDER, y: PAGE_START-HEADER_HEIGHT-2*BORDER-(j+1)*(LINE_HEIGHT+BORDER)-offset, width: COLUMN_3+COLUMN_4+COLUMN_5-BORDER, height: LINE_HEIGHT, color: j===0?rgb(230/255,230/255,230/255):rgb(1,1,1)});
            const font = await pdfPayment.embedFont(StandardFonts.Helvetica);
            const txt = (lines[j][1] as string),
            txtWidth=font.widthOfTextAtSize(txt, 13), 
            hPos=(PAGE_WIDTH-TABLE_WIDTH)/2+COLUMN_1+COLUMN_2+COLUMN_3+COLUMN_4+COLUMN_5-txtWidth-BORDER-CELL_PADDING
            pdfPayment.getPage(0).drawText(lines[j][1].toString(), {x: hPos, y: PAGE_START-HEADER_HEIGHT-2*BORDER-(j+0.66666)*(LINE_HEIGHT+BORDER)-offset, size: 13, color: rgb(0,0,0)});
        }
    }
    }
    let pdfContract = (await PDFDocument.load(await q.pdfs[q.contract.pdfContract].arrayBuffer()))
    if (q.language==='de' && q.quoteType!=='signature')
    {
    pdfContract=await PDFDocument.create();
    pdfContract.addPage();
    pdfContract.getPage(0).drawText("KAUFVERTRAG -",{x: 10, y: 750, size: 10, color: rgb(0,0,0)});pdfContract.getPage(0).drawText("https://drive.google.com/file/d/1kdAzxY2M3ltfo_sBHHotytqaWK4VvMUX/view?usp=sharing", {x: 150, y: 750, size: 10, color: rgb(0,0,0.8)})
    pdfContract.getPage(0).drawText("ENDBENUTZER-LIZENZVERTRAG -",{x: 10, y: 730, size: 10, color: rgb(0,0,0)});pdfContract.getPage(0).drawText("https://drive.google.com/file/d/1uIcctsBuWfwG9OR4Bw_HJKCI55T01P6E/view?usp=sharing", {x: 180, y: 730, size: 10, color: rgb(0,0,0.8)})
    pdfContract.getPage(0).drawText("Garantien -",{x: 10, y: 710, size: 10, color: rgb(0,0,0)});pdfContract.getPage(0).drawText("https://drive.google.com/file/d/1SPjem4IdTWZSzqXEwFjsggzvffvlBe-o/view?usp=sharing", {x: 150, y: 710, size: 10, color: rgb(0,0,0.8)})
    pdfContract.getPage(0).drawText("Geschäftsbedingungen -",{x: 10, y: 690, size: 10, color: rgb(0,0,0)});pdfContract.getPage(0).drawText("https://drive.google.com/file/d/1RxGAoQc9sWYKbo7O0ko18FDo6oyYxvrn/view?usp=sharing", {x: 150, y: 690, size: 10, color: rgb(0,0,0.8)})
    
    }

    if (isUSA)
    { 
        if (q.quoteType!== 'signature'){
            pdfContract=await PDFDocument.create();
            pdfContract.addPage();
            pdfContract.getPage(0).drawText("SALES CONTRACT -",{x: 10, y: 750, size: 10, color: rgb(0,0,0)});pdfContract.getPage(0).drawText("https://drive.google.com/file/d/1DeM0F-1QpMApMB5fQDCOaGYphxV39gtp/view?usp=sharing", {x: 150, y: 750, size: 10, color: rgb(0,0,0.8)})
            pdfContract.getPage(0).drawText("WARRANTY -",{x: 10, y: 730, size: 10, color: rgb(0,0,0)});pdfContract.getPage(0).drawText("https://drive.google.com/file/d/1GZ8P_O6kiyxJuXw6REi76Y90UGKj4LBb/view?usp=sharing", {x: 150, y: 730, size: 10, color: rgb(0,0,0.8)})
            pdfContract.getPage(0).drawText("EULA -",{x: 10, y: 710, size: 10, color: rgb(0,0,0)});pdfContract.getPage(0).drawText("https://drive.google.com/file/d/1lUt4SYJW1Hi2tUikdVvhRg5q6V7tFcv-/view?usp=sharing", {x: 150, y: 710, size: 10, color: rgb(0,0,0.8)})
            pdfContract.getPage(0).drawText("TERMS AND CONDITIONS -",{x: 10, y: 690, size: 10, color: rgb(0,0,0)});pdfContract.getPage(0).drawText("https://drive.google.com/file/d/1Smu6wjPhZbkZSD4t7-XvT2gxJ3p9tadh/view?usp=sharing", {x: 150, y: 690, size: 10, color: rgb(0,0,0.8)})
            pdfContract.getPage(0).drawText("W9 -",{x: 10, y: 670, size: 10, color: rgb(0,0,0)});pdfContract.getPage(0).drawText("https://drive.google.com/file/d/1KPccpsLz5tXBvYUI2LJlgFfKGMWvc7eB/view?usp=sharing", {x: 150, y: 670, size: 10, color: rgb(0,0,0.8)})

        }
        const pdf=(await PDFDocument.load(await q.pdfs[q.quoteType==='signature'?q.contract.pdfFinal:q.contract.pdfBudgetary].arrayBuffer()))
        if (q.quoteType==='signature')
        {
        const page1=await pdfContract.copyPages(pdf,[0,1])
        page1[0].drawText(quotationString.split('-')[1],{x: 383, y: 602.5, size: 6, color: rgb(0,0,0)})
        pdfContract.insertPage(2,page1[0])
        pdfContract.insertPage(3,page1[1])
        }
        else
        {
        const page1=await pdfContract.copyPages(pdf,[0])
        page1[0].drawText(quotationString.split('-')[1],{x: 110, y: 489.5, size: 8, color: rgb(0,0,0)})
        pdfContract.insertPage(1,page1[0])
        }
    }

    const mergedPdf = await PDFDocument.create();
    (await mergedPdf.copyPages(pdfCover, pdfCover.getPageIndices())).forEach((page) => mergedPdf.addPage(page));
    for (const doc of pdfPayments)
        (await mergedPdf.copyPages(doc, doc.getPageIndices())).forEach((page) => mergedPdf.addPage(page));
    (await mergedPdf.copyPages(pdfContract, pdfContract.getPageIndices())).forEach((page) => mergedPdf.addPage(page));
    mergedPdf.setAuthor(q.user.firstname+" "+q.user.lastname);
    mergedPdf.setCreator("TinyMobileRobots");
    mergedPdf.setProducer("TinyMobileRobots");
    mergedPdf.setCreationDate(q.date.toDate());
    mergedPdf.setModificationDate(q.date.toDate());
    mergedPdf.setLanguage("en-us");
    mergedPdf.setSubject(tables[0]?.title ?? "TinyMobileRobots Contract");
    mergedPdf.setTitle(tables[0]?.title ?? "TinyMobileRobots Contract");
    
    const blob = new Blob([(await mergedPdf.save())], {type: "application/pdf"});
    const url = URL.createObjectURL(blob);
    const file = new File([blob], quotationString+".pdf");
    return { lineItems, file, url };
}






