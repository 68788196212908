import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { setCustomersDto } from "src/redux/app/actions";
import ConfigurationsService from "src/services/ConfigurationsService";
import type { Customer } from "src/services/DeviceDataService";
import { useAsync, useErrorHandler, useLocalStorage, useToast } from "src/hooks";
import { customersDtoSelector, customersArraySelector, dealersSelector } from "src/redux/app/selectors";
import AutocompleteV2 from "src/components/AutocompleteV2";
import { FormControlLabel, Switch } from "@material-ui/core";
import { userAccessSelector } from "src/redux/auth/selectors";

type SetCustomerParentInput = {
    customer: Customer | null;
    parent: Customer | null;
};

function setCustomerName(input: SetCustomerParentInput) {
    return ConfigurationsService.setCustomerParent(input.customer!.id, input.parent!.id).then(() => input);
}

export default function CustomerParentWidget({ widgetName ,saveChanges, favouriteWidgets, setFavouriteWidgets }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        reset,
        register,
        setValue,
        watch,
    } = useForm<SetCustomerParentInput>({
        defaultValues: { customer: null, parent: null },
    });

    const { displayToast } = useToast();
    const customersDto = useSelector(customersDtoSelector);

    const customer = watch("customer");
    const parent = watch("parent");

    const [customerParentWidgetLastCustomerId,setCustomerParentWidgetLastCustomerId]=useLocalStorage("CustomerParentWidgetLastCustomerId",-1)
    const [selectCustomerAsDealer, setSelectCustomerAsDealer] = useState(false);
   
    useEffect(() => {
        register("customer", defaultRules);
        register("parent", defaultRules);
    }, [register]);

    const onComplete = useCallback(
        (input: SetCustomerParentInput) => {
            if (input.customer != null) {
                customersDto[input.customer.id].parentId = input.parent!.id;
                if(customersDto[input.customer.id].isDealer)
                    customersDto[input.customer.id].dealerId = input.parent!.id;
                else 
                    customersDto[input.customer.id].dealerId = customersDto[input.parent!.id].dealerId;
                setCustomersDto(customersDto);
                //TODO: why only customer dto?
            }
            if (!saveChanges)
            reset();
            displayToast({
                message: `Successfully set the parent of ${
                    input.customer!.name
                } to ${input.parent!.name}`,
                severity: "success",
                withCloseIcon: true,
            });
        },
        [displayToast, reset, customersDto,saveChanges]
    );

    const { exec: setName, pending: settingName, error } = useAsync(
        setCustomerName,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
            onComplete,
        }
    );

    const customers : Customer[] = useSelector(customersArraySelector);
    const dealers : Customer[] = useSelector(dealersSelector);
    const tmp = dealers.map(d => d.id);
    const notDealers : Customer[] = customers.filter(c => !tmp.includes(c.id));

    const loading = settingName || customers.length === 0 || dealers.length === 0;
    const allowCustomerAsDealerAccess=useSelector(userAccessSelector).includes('ChooseCustomerAsDealer')
    useEffect(()=>{
        if (saveChanges){
            
            if (customerParentWidgetLastCustomerId && customerParentWidgetLastCustomerId!==customer?.id){
                if(dealers.find(c => c.id === customerParentWidgetLastCustomerId))
                    setValue("customer", null);
                else {
                    setValue("customer", customers.find(c=>c.id===customerParentWidgetLastCustomerId))
                    setValue('parent', dealers.find(d => d.id === customersDto[customerParentWidgetLastCustomerId]?.dealerId) ?? null)
                }
            }
        }
    },[saveChanges, customerParentWidgetLastCustomerId, setValue, customer, customersDto, dealers, customers])

    return (
        <BaseWidget
            widgetName={widgetName}
            title="Change customer dealer"
            subTitle="Change the parent of your customers."
            onSubmit={handleSubmit(setName)}
            loading={loading}
            error={error}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            {allowCustomerAsDealerAccess && 
            <div style={{width:"100%",display:"flex",justifyContent:"center",marginBottom:"5px"}}>
                <FormControlLabel
                    control={
                        <Switch
                            size="small"
                            checked={selectCustomerAsDealer}
                            onChange={(ev) => {
                                setSelectCustomerAsDealer(ev.target.checked);
                                if(saveChanges)
                                    setCustomerParentWidgetLastCustomerId(-1);
                                setValue("parent",null);
                                setValue("customer",null);
                            }}
                        />
                    }
                    label="Allow any association"
                />
            </div>
            }
            <AutocompleteV2
                selectOnTab
                value={customer}
                disabled={loading}
                options={selectCustomerAsDealer?customers.filter(c => c.id !== 1):notDealers}
                getOptionLabel={(r) => `(${r.id}) ${r.name}`}
                getOptionSelected={(a, b) => a.id === b.id}
                noOptionsText="No such customer"
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label="Customer"
                            placeholder="Choose a customer"
                            required
                            {...getErrorProps(errors, "customer")}
                        />
                    );
                }}
                onChange={(_ev, val) => {
                    setValue("customer", val);
                    if (parent === null && val !== null)
                    {
                        if(selectCustomerAsDealer)
                            setValue("parent", customers.find(d => d.id === customersDto[val.id].parentId));
                        else
                            setValue("parent", dealers.find(d => d.id === customersDto[val.id].dealerId));
                    }
                    if (saveChanges){
                        setCustomerParentWidgetLastCustomerId(val?.id??-1)}
                }}
            />
            <AutocompleteV2
                selectOnTab
                value={parent}
                disabled={loading}
                options={selectCustomerAsDealer?customers:dealers}
                getOptionLabel={(r) => `(${r.id}) ${r.name}`}
                getOptionSelected={(a, b) => a.id === b.id}
                noOptionsText="No such dealer"
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            label={selectCustomerAsDealer?"Dealer/Parent":"Dealer"}
                            placeholder="Choose a dealer"
                            required
                            {...getErrorProps(errors, "parent")}
                        />
                    );
                }}
                onChange={(_ev, val) => {
                    setValue("parent", val);
                }}
            />

        </BaseWidget>
    );
}
